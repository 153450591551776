@import './styles/breakpoints.scss';
@import './styles/media-queries.scss';
@import './styles/max-props.scss';

@font-face {
  font-family: Windsor;
  src: url('../public/self-modern.woff2');
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#canvas-container {
  background-color: #282c34;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.wrapper {
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
}

.project-description {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  p {
    margin-bottom: calc(10px + 0.5vw);
  }
  @include isPortrait {
    top: 20px;
    height: 100%;
    pointer-events: none;
  }
  @include isLandscape {
    top: calc(-230px - 8vw);
  }
}

.project {
  padding: calc(20px + 1.7vw);
  left: 0;
  display: grid;
  grid-template-columns: 30vw;
  column-gap: 5vw;
  @include isPortrait {
    // display: none;
    grid-template-columns: 90vw;
    margin-top: 130px;
  }
}

.subtitle {
  margin-left: 10px;
  &:before {
    content: '-';
    padding-right: 5px;
  }
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  padding: calc(20px + 1.7vw);
  display: flex;
  align-items: baseline;
}

h2, h1 {
  font-family: Windsor;
  margin-bottom: 10px;
  color: #d3bff1;
}

h1 {
  @include max-prop(font-size, 16px, calc(12px + 0.5vw));
  @include isPortrait {
    @include max-prop(font-size, 14px, calc(11px + 0.3vw));
  }
}

h2 {
  @include max-prop(margin-top, 30px, calc(10px + 0.7vw));
  @include max-prop(font-size, 14px, calc(11px + 0.3vw));
}

p, li {
  @include max-prop(font-size, 12px, calc(10px + 0.2vw));
  line-height: 1.3;
  color: white;

  &.small {
    @include max-prop(font-size, 12px, calc(10px + 0.1vw));
  }
  &.no-margin {
    margin-bottom: 0;
  }
}

.clickable > li > a {
  text-decoration: underline;
  &:hover {
    color: yellow;
  }
}

li {
  list-style-type: none;
}

a {
  color: inherit;
  cursor: pointer;
}

.mail {
  text-decoration: none;
}

.text-container {
  position: absolute;
  // pointer-events: none;
  width: 100%;
  @include isPortrait {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }
  @include isLandscape {
    bottom: 0;
  }
}

.text {
  padding: calc(20px + 1.7vw);
  left: 0;
  display: grid;
  grid-template-columns: 10vw 10vw 13vw 30vw 10vw;
  column-gap: 5vw;
  @include isPortrait {
    margin-bottom: calc(30px + 1.7vw);
    padding-top: 60px;
    grid-template-columns: 80vw;
    column-gap: 5vw;
  }
}

.bottom-aligned {
  @include isPortrait {
    margin-top: calc(300px + 1.7vw);
  }
}

.row {
  display: flex;
  gap: calc(10px + 1.7vw);
}

.projects {
  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  @include isPortrait {
    margin-left: calc(-20px - 1.7vw);
    width: calc(100% + 3 * (20px + 1.7vw));
    display: flex;
    overflow-x: scroll;
    li {
      margin-right: calc(10px + 1vw);
      background: #d3bff1;
      padding: calc(6px + 0.2vw) calc(8px + 0.2vw);
      border-radius: 15px;
      flex: 0 0 auto;
      &:first-child {
        margin-left: calc(20px + 1.7vw);
      }
      a {
        text-decoration: none;
      }
    }
  }
}